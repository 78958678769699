module.exports = {
  title: `Nlog`,
  description: `Blog posted about IT`,
  author: `[namu]`,
  introduction: `모바일, 스마트폰, 금융, 재테크, 생활 정보 등`,
  siteUrl: `https://blog.iinfo.kr/`, // Your blog site url
  social: {
    twitter: ``, // Your Twitter account
    github: ``, // Your GitHub account
    medium: ``, // Your Medium account
    facebook: ``, // Your Facebook account
    linkedin: ``, // Your LinkedIn account
    instagram: ``, // Your Instagram account
  },
  icon: `content/assets/felog.png`, // Add your favicon
  keywords: [`blog`, '모바일', '스마트폰', 'IT', 'tech', 'info', '블로그', '금융', '재테크', '생활'],
  comment: {
    //disqusShortName: '', // Your disqus-short-name. check disqus.com.
    disqusShortName: '', // Your disqus-short-name. check disqus.com.
    utterances: '', // Your repository for archive comment
    //utterances: 'nhj7/gatsby-nhj-blog', // Your repository for archive comment
  },
  configs: {
    countOfInitialPost: 20, // Config your initial count of post
  },
  sponsor: {
    buyMeACoffeeId: '',
  },
  share: {
    facebookAppId: '', // Add facebookAppId for using facebook share feature v3.2
  },
  ga: '', // Add your google analytics tranking ID
  ad: 'ca-pub-6043926021201031', // Add your google adsense publisherId `ca-pub-xxxxxxxxxx`

}
